.drawer {
  --drawer-toolbar-content-padding: 0 10px 0 0;
  --nav-footer-offset: 360px;
  font-family: 'DOJO Roobert', Arial, sans-serif;
}

.nav {
  --nav-footer-position: fixed;
  --nav-routes-container-height: calc(100vh - var(--nav-footer-offset));
}

@media (max-width: 599px) {
  .nav {
    --nav-routes-container-overflow: auto;
  }
}

.navItem_icon__DccJY svg {
  width: 24px;
  height: 24px;
}

.backBtn {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid var(--app-black-alpha-11);
  cursor: pointer;
}

.backIcon {
  margin-right: 12px;
}

.backIcon > svg {
  height: 24px;
  width: 24px;
  display: flex;
}

.internalUserHeader {
  padding: 16px 24px;
}
