.layoutContainer {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content 1fr;
  grid-template-areas:
    'banner banner'
    'aside header'
    'aside content';
  height: 100vh;
  max-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
  position: relative;
  width: 100vw;
}

.aside {
  grid-area: aside;
}

.header {
  grid-area: header;
}

.content {
  grid-area: content;
  overflow: inherit;
}

.appBanner {
  grid-area: banner;
  min-height: 0 !important;
}
