@media only screen and (max-width: 960px) {
  .transaction-mobile-filters-dialog {
    --border-radius: 0;
    --height: 100%;
    --max-height: 100%;
    --max-width: 100%;
    --min-width: 100%;
    --width: 100%;
    padding: 0;
  }

  .transaction-mobile-filters-dialog .modal-wrapper {
    --box-shadow: none;
    --height: 100%;
    --max-height: 100%;
  }
}
