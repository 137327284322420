/* introduce scroll to the modal that exceeds the height of the viewport */
.limit-height-for-scroll {
  /* RMCX-1773 test overwritting web-components stuff and use the same as ion class sc-ion-modal-md-h  */
  --height: 100%;
}

.small-dialog .modal-wrapper {
  --height: auto;
  --border-radius: 4px;
  --box-shadow: 0px 10px 32px 0px var(--app-black-alpha-40);
  margin: 0 20px;
}

.small-dialog-width .modal-wrapper {
  max-width: 300px;
  --height: auto;
  --border-radius: 4px;
  --box-shadow: 0px 10px 32px 0px var(--app-black-alpha-40);
  margin: 0 20px;
}

.dialog-full-width {
  --border-radius: 0;
  --height: 100%;
  --max-height: 100%;
  --max-width: 100%;
  --min-width: 100%;
  --width: 100%;
  padding: 0;
}

.djp-bottom-sheet {
  --border-radius: 16px 16px 0px 0px;
  --width: 100%;
  --height: 210px;
  --box-shadow: 0px 4px 12px var(--app-black-alpha-40);
  z-index: 2 !important;
}

.dialog {
  --overflow: auto;
}

@media screen and (max-width: 600px) {
  .remote-payments-dialog {
    --height: 100%;
    --width: 100%;
  }
  /* expand backdrop to full width on mobile so content underneath is not visible */
  .teams-dialog ion-backdrop {
    --height: 100vh;
    --max-height: 100vh;
    --ion-backdrop-color: #fff;
    --backdrop-opacity: 1;
  }
}

@media screen and (min-width: 601px) {
  .remote-payments-dialog {
    --max-width: 560px;
    --max-height: 1024px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  .add-team-member-dialog,
  .remote-payments-dialog,
  .teams-dialog {
    --height: auto;
  }
  .product-order-dialog {
    --width: 960px;
  }
  .supervisor-code-dialog {
    --width: 448px;
    --height: fit-content;
    --overflow: auto;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .add-team-member-dialog,
  .remote-payments-dialog,
  .teams-dialog {
    --height: auto;
  }
}
