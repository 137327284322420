@import '~@paymentsense/dj-components/dist/dj-components/dj-components.css';

/* components custom ion global styles */
@import './dropdown.css';
@import './dialog.css';
@import './invoice-dialogs.css';
@import './transactions-mobile-filters-dialog.css';
@import './typography.css';

:root {
  font-family: 'DOJO Roobert', Arial, sans-serif, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-family: var(--font-family);
  font-size: 16px;
}

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Workaround for a bug in Ionic v6 - popover content not showing */
[popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.invoices-list-popover .popover-content {
  margin-top: 8px;
  width: 295px;
}

.osano-cm-dialog {
  background: #eaf7f7;
  color: #5c7291;
  font-family: 'DOJO Roobert', Arial, sans-serif;
}

.osano-cm-dialog--type_bar {
  box-sizing: border-box;
  display: flex;
  right: 0;
}

.osano-cm-link {
  color: #29246a;
  cursor: pointer;
  text-decoration: underline;
  transition-duration: 0.2s;
  transition-property: color;
  transition-timing-function: ease-out;
}

.osano-cm-dialog {
  align-items: center;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.25;
  overflow: auto;
  padding: 1.5em;
  position: fixed;
  transition-delay: 0ms, 0ms;
  transition-duration: 0.7s, 0ms;
  transition-property: opacity, visibility;
  visibility: visible;
  z-index: 2147483637;
}

.osano-cm-window {
  direction: ltr;
  text-align: left;
  pointer-events: auto;
}

.invoices-location-list-popover .popover-content {
  margin-top: 8px;
  width: 400px;
}

@media only screen and (max-width: 400px) {
  .invoices-location-list-popover .popover-content {
    width: 288px;
  }
}

.switch-account-popover .popover-content {
  width: 272px;
}

.switch-account-popover .popover-content dj-dropdown-popover {
  /* need to add alpha shade in web components */
  --dropdown-item-background-color: var(rgba(0, 0, 0, 0.04));
}

.card-machine-desktop-filter dj-dropdown-popover {
  --dropdown-item-flex-wrap: nowrap;
  --dropdown-item-gap: 3px;
}

.invoice-line-actions .popover-content {
  --width: 280px;
}

.invoice-preview-dialog {
  --width: 1024px;
}

@media only screen and (min-width: 600px) {
  .pre-auth-collection-dialog-holder {
    --width: 448px;
  }
}

@media only screen and (min-width: 600px) {
  .pre-auth-release-dialog-holder {
    --width: 448px;
  }
}

.accounting-integrations-popover {
  --width: 492px;
  --max-height: 428px;
}

@media only screen and (max-width: 600px) {
  .accounting-integrations-popover {
    --width: 87%;
  }
}

.osano-cm-denyAll {
  display: none !important;
  position: absolute !important;
}

.osano-cm-dialog--hidden {
  opacity: 0;
  transition-delay: 0ms, 0.7s;
  visibility: hidden;
  left: -99999999999px !important;
}

.osano-cm-dialog__close {
  display: none !important;
  position: absolute !important;
}

.osano-cm-manage.osano-cm-button {
  background: transparent !important;
  border: 1px solid #262626 !important;
  color: #262626;
  order: 1;
}

.osano-cm-button--type_manage {
  background: transparent !important;
  border: 1px solid #262626 !important;
  color: #262626;
  order: 1;
}

.osano-cm-dialog__buttons {
  display: flex !important;
  flex: 1 !important;
  justify-content: flex-end;
  margin: 0;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  flex: 1 1 !important;
}

.osano-cm-button {
  border-style: solid !important;
  border-width: thin !important;
  flex: 1 1 auto !important;
  font-size: 1em !important;
  line-height: 1 !important;
  margin: 0.125em !important;
  padding: 0.5em 0.75em !important;
  background-color: #000000 !important;
  border-color: #ffffff !important;
  width: 100% !important;
}
@media screen and (max-width: 767px) {
  .osano-cm-accept-all {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 768px) {
  .osano-cm-button {
    flex: 1 1 100% !important;
    margin: 0.25em 0.5em !important;
  }
}
.osano-cm-window__dialog {
  content: 'Cookie consent';
  font-size: 19px;
  color: #262626 !important;
}
.osano-cm-close {
  border-style: solid !important;
  border-width: 2px !important;
  box-sizing: content-box !important;
  line-height: normal !important;
  margin: 0.5em !important;
  overflow: hidden !important;
  padding: 0 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
.osano-cm-toggle__switch {
  box-sizing: content-box !important;
}
.osano-cm-dialog__content.osano-cm-content {
  color: #262626 !important;
  font-size: 16px;
}
.osano-cm-dialog__content.osano-cm-content:before {
  content: 'Cookie consent';
  display: block;
  font-size: 19px;
  margin-bottom: 8px;
  color: #262626 !important;
  font-weight: bold;
}
.osano-cm-window__widget {
  display: none !important;
}
