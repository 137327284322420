@media only screen and (max-width: 600px) {
  .delete-invoice-modal .modal-wrapper {
    --height: auto;
    --max-height: auto;
    --min-width: 288px;
    --border-radius: 4px;
    margin: 0 16px;
  }
}

@media only screen and (max-width: 960px) {
  .invoice-filter-dialog {
    --border-radius: 0;
    --height: 100%;
    --max-height: 100%;
    --max-width: 100%;
    --min-width: 100%;
    --width: 100%;
    padding: 0;
    position: fixed;
  }

  .invoice-filter-dialog .modal-wrapper {
    --box-shadow: none;
    --height: 100%;
    --max-height: 100%;
  }
}
