.reason-dropdown {
  --width: 100%;
  --max-width: 512px;
}

@media only screen and (max-width: 960px) {
  .reason-dropdown {
    --max-width: 400px;
  }
}

@media only screen and (max-width: 600px) {
  .reason-dropdown {
    --max-width: calc(100% - 32px);
  }
}

.transaction-filter-dropdown {
  --width: 100%;
  --max-width: 300px;
}
