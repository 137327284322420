.wrapper {
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--app-black-alpha-11);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px;
}

.image {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: auto;
  width: 100%;
}

.context {
  padding: 24px;
}

@media (min-width: 599px) {
  .wrapper {
    max-width: 257px;
  }

  .image {
    max-height: 79px;
    max-width: 257px;
  }
}

.bodyText {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
