.wrapper {
  padding: 4px 13px;
}

.item {
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  max-height: 56px;
  padding: 12px;
}

.active {
  background-color: var(--app-secondary-200);
}

.sub {
  padding-left: 48px;
}

.item:hover {
  background-color: var(--app-secondary-50);
}

.icon {
  display: inline-block;
  height: 24px;
  margin-right: 10px;
  width: 24px;
}

.icon svg {
  fill: var(--app-black-alpha-60);
}

.text {
  font-family: var(--app-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--app-black);
}

.chip {
  line-height: 24px;
  font-size: 0.875rem;
  border-radius: 12px;
  line-height: 28px;
  padding: 0 8px;
  width: fit-content;
  border-width: 1px;
  border-style: solid;
  border-color: var(--app-primary-main-color);
  color: var(--app-primary-main-color);
  margin-left: 10px;
}
