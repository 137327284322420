.black {
  color: var(--app-black);
  font-family: '"DOJO Roobert", Arial, sans-serif';
}

.medium-emphasis {
  color: var(--app-greyscale-38);
  font-family: '"DOJO Roobert", Arial, sans-serif';
}

.negative-red {
  color: var(--app-negative);
  font-family: '"DOJO Roobert", Arial, sans-serif';
}

.intermediate-orange {
  color: var(--app-intermediate);
  font-family: '"DOJO Roobert", Arial, sans-serif';
}
