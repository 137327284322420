.chevronIcon {
  color: var(--app-black-alpha-60);
  cursor: pointer;
  display: inline-block;
  height: 24px;
  position: absolute;
  right: 22px;
  top: 16px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  width: 24px;
}

.rotated {
  transform: rotate(180deg);
}

.folder {
  border: 0;
  background-color: transparent;
  position: relative;
  padding: 0;
  margin: 0;
}

.collapsible {
  overflow: hidden;
  transition: max-height 0.2s;
}
